export const faqData = [
  {
    question: 'Is this IELTS website gives me an exact score?',
    answer: 'Most of the time the difference is not more than 0.5, but sometimes up to 1.5 band. It\'s best to do 3-4 submissions.'
  },

  {
    question: 'Can you check my essay writing?',
    answer: 'Click the \'Check Assay\' on the navigation bar or the \'Try now free\' button. This will navigate you to the page where you can check your essay writing. You need to fill out the form and get your result, two checks are for free and then paid.'
  },


  {
    question: 'Can you check my ielts task 1?',
    answer: 'This is not yet possible. But if you are willing to buy a paid plan and send a note regarding that to support it would speed up development of this feature.'
  }
]