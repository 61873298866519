'use client';

import { useState, useEffect } from 'react';
import { getCookie } from 'cookies-next';

const useIsAuthorized = () => {
    const [isAuthorized, setIsAuthorized] = useState(false);

    useEffect(() => {
        // Check if 'auth_session' cookie exists and is not empty
        const authSession = getCookie('auth_session');
        setIsAuthorized(!!authSession); // Set to true if 'auth_session' is not empty, otherwise false
    }, []);

    return { isAuthorized };
};

export default useIsAuthorized;

