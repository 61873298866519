import { Collapse } from 'react-collapse';
import { RiArrowDownSLine, RiArrowUpSLine } from "react-icons/ri";

type FaqData = {
  open: boolean;
  toggle: () => void;
  question: string;
  answer: string;
}

const FaqItem = ({open, toggle, question, answer}: FaqData) => {

  return <>
  <div className='rounded-lg border border-slate-400 p-2'>
  <button className='w-full flex justify-between items-center' onClick={toggle}>
    <span className='text-lg font-bold text-blue-900'>{question}</span>
    <span >{open ? <RiArrowUpSLine className="text-2xl text-slate-400"/> : <RiArrowDownSLine className="text-2xl text-slate-400"/>}</span>
  </button>
    <Collapse isOpened={open}>
      <div className="text-sm mt-2">{answer}</div>
    </Collapse>
  </div>

  </> 
};

export default FaqItem;
