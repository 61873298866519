import Image from "next/image";
import { faqData } from "./FaqData";
import FaqItem from "./FaqItem";
import { useState } from "react";


const Faq = () => {

  const [open, setOpen] = useState<boolean | number>(false);

  const toggleHandler = (index: boolean | number) => {
    if(open === index) {  
      return setOpen(false)
    }
    return setOpen(index)
  }

  return (
    <section className="grid md:grid-cols-2 md:h-96 gap-7 md:gap-10 place-items-start mb-16">
        <Image 
          src='/faq-image.webp'
          width={500}
          height={500}
          alt='Faq image'
        />
      <div className="w-full grid gap-5">
        <h2 className='text-blue-900 text-4xl font-bold text-center md:text-start md:mb-4'>FAQ</h2>
          {faqData.map((data, index) => {
            return <FaqItem key={index} open={open === index} toggle={() => toggleHandler(index)} question={data.question} answer={data.answer}/>
          })}
      </div>
    </section>
  )
}

export default Faq;
