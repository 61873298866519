export const tools = [
    {
        id: 1,
        title: 'AI Checker for Writing Task 2 🔥',
        summary: 'Boost your IELTS Writing score with our free online AI trainer. Receive instant feedback, tailored improvement suggestions, and practice with our cutting-edge evaluation platform to achieve your best results.',
        imgSrc: '/trainer-preview.png',
        link: '/trainer',
        free: 'Free Trial',
    },
    {
        id: 3,
        title: 'Words Counter for Writing Task 2',
        summary: 'Check your IELTS Writing word count, character count, sentence count, and paragraph count with our free online tool.',
        imgSrc: '/word-counter-screen.png',
        link: '/tools/ielts-word-counter',
        free: 'Free',
    },
    {
        id: 6,
        title: 'Speaking Test',
        summary: 'This practice will take you through all three parts of the speaking section, similar to the actual exam.',
        imgSrc: '/speaking-trainer.png',
        link: '/speaking',
        free: 'Free',
    },
    {
        id: 4,
        title: 'Smart Essay Templates for Writing Task 2',
        summary: 'Perfect your essay structure for IELTS Writing Task 2 with our interactive essay templates. Practice writing different types of essays, get tips on what to include in each section, and see sample questions for each template.',
        imgSrc: '/essay-template-screen.png',
        link: '/tools/essay-template/opinion',
        free: 'Free',
    },
    {
        id: 5,
        title: 'IELTS Overall Score Calculator',
        summary: 'Calculate your IELTS score online and understand what your score means for studying or immigration. Follows 2023 official IELTS rules.',
        imgSrc: '/ielts-score-calculator-screen.png',
        link: '/tools/ielts-score-calculator',
        free: 'Free',
    },
    {
        id: 2,
        title: 'Collocation Panda Game',
        summary: 'Learn IELTS collocations with Collocation Panda, a free game that helps you memorize the most frequent collocations for IELTS Writing Task 2. Improve your writing skills today!',
        imgSrc: '/collocation-panda-preview.webp',
        link: '/collocation-panda',
        free: 'Free',
    },
];