'use client';

import React, { useState, useEffect } from 'react';
import { AiOutlineCheck, AiOutlineTool, AiOutlineRead, AiOutlineProfile, AiOutlineLogin} from 'react-icons/ai';
import { BsPencil } from 'react-icons/bs';
import { BiUserVoice } from "react-icons/bi";
import { usePathname } from 'next/navigation';
import { articles } from '../data/articles';
import { tools } from '../data/tools';

import useIsAuthorized from '@/hooks/useIsAuthorized';


function Header() {

    const { isAuthorized } = useIsAuthorized();

    const [activeMenu, setActiveMenu] = useState<number | null>(null);
    const [isTouchDevice, setIsTouchDevice] = useState(false);

    const pathname = usePathname();

    useEffect(() => {
        setIsTouchDevice('ontouchstart' in window);
    }, []);

    const handleMouseEnter = (index: number) => {
        if (!isTouchDevice) setActiveMenu(index);
    }

    const handleMouseLeave = () => {
        if (!isTouchDevice) setActiveMenu(null);
    }

    const handleTouch = (index: number, hasSubItems: any, e: any) => {
        if (isTouchDevice) {
            if (hasSubItems) {
                e.preventDefault();
                setActiveMenu(activeMenu === index ? null : index);
            }
        }
    }

    const menuItems = [
        { title: 'Check Essay', link: '/trainer', icon: <AiOutlineCheck /> },
        { title: 'Speaking Test', link: '/speaking', icon: <BiUserVoice /> },
        { title: 'Samples', link: '/ielts-writing-samples', icon: <BsPencil /> },
        {
            title: 'Tools', link: '/tools', icon: <AiOutlineTool />,
            subItems: tools.map(tool => ({ link: tool.link, title: tool.title }))
        },
        {
            title: 'Articles', link: '/articles', icon: <AiOutlineRead />, subItems: articles.map(article => ({
                title: article.title,
                link: article.link
            }))
        },
        isAuthorized ?
            { title: 'Profile', link: '/my-profile', icon: <AiOutlineProfile /> } :
            { title: 'Sign in', link: '/login', icon: <AiOutlineLogin /> },
    ];

    return (
        <header className="bg-blue-900 w-full z-10">
            <div className="p-4 max-w-4xl mx-auto flex justify-between items-center flex-wrap sm:flex-nowrap">
                <div className="flex items-center">
                    <a href="/" className="text-white font-bold text-base sm:text-lg mb-2 sm:mb-0">UpScore.ai</a>
                </div>
                <div className="flex text-center items-stretch text-white font-bold text-xs mt-2 sm:mt-0 sm:text-base space-x-0 sm:space-x-2">
                    {menuItems.map((item, index) => (
                        <div key={item.link} className="relative group cursor-pointer" onMouseEnter={() => handleMouseEnter(index)} onMouseLeave={handleMouseLeave}>
                            <a href={item.link} onClick={(e) => handleTouch(index, item.subItems, e)} className={`inline-flex flex-col sm:flex-row px-2 py-2 hover:bg-blue-800 items-center justify-center ${pathname?.includes(item.link) ? 'bg-blue-700' : ''}`}>
                                {item.icon}
                                <span className={`ml-1 sm:ml-2 ${item.title === "Check Essay" ? "text-green-400" : ""} ${item.title === "Speaking Test" ? "text-green-400" : ""}`}>{item.title}</span>
                            </a>
                            {item.subItems && activeMenu === index && (
                                <div className="absolute -left-14 bg-blue-800 text-white w-48 shadow-lg text-xs">
                                    {item.subItems.map(subItem => (
                                        <a key={subItem.link} href={subItem.link} className="border-blue-900 border-t block px-4 py-2 hover:bg-blue-700 hover:text-white">{subItem.title}</a>
                                    ))}
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </header>
    );
}

export default Header;
