// import { Fragment } from 'react';
import Image from "next/image";
import Link from 'next/link';
import fileImage from '/public/file-image.webp';

const ScoreComparison = () => {
  
  return <>
    <h2 className="text-center text-blue-900 text-3xl mb-7 mt-12">SCORES GIVEN BY UPSCORE.AI VS OFFICIAL SCORES</h2>
    <section className="grid grid-cols-2 gap-10">
      <div className='flex justify-center items-center h-28 md:h-48 rounded-lg border border-zinc-300 relative'>
        <div className="flex items-center">
          <div className="px-2 md:px-4">
            <h2 className='text-blue-900 md:text-2xl font-bold'>UPSCORE.AI</h2>
            <p className='text-stone-500 text-xs md:text-lg font-normal'>writing score analysis</p>
            <span className='text-blue-900 text-5xl md:text-7xl font-normal'>8.5</span>
          </div>
          <div className="pr-2 md:pr-0 md:mx-10">
            <Image src={fileImage} alt='writing score analysis' className="w-14"/> 
          </div>
        </div>
        <div className='w-10 left-5 md:inset-x-1/4 md:w-1/4 h-16 md:h-20 top-full border-zinc-300 border-b-4 border-l-4 absolute'></div>
      </div>

      <div className='flex justify-center items-center h-28 md:h-48 rounded-lg border border-zinc-300 relative'>
        <div className="flex items-center">
          <div className="px-2 md:px-4">
            <h2 className='text-stone-500 md:text-2xl font-bold'>EXAMINERS</h2>
            <p className='text-stone-500 text-xs md:text-lg font-normal'>writing score analysis</p>
            <span className='text-stone-500 text-5xl md:text-7xl font-normal'>8.0</span>
          </div>
          <div className="pr-2 md:pr-0 md:mx-10">
            <Image src={fileImage} alt='writing score analysis' className="w-14"/> 
          </div>
        </div>
        <div className='w-10 right-5 md:inset-x-2/4 md:w-1/4 h-16 md:h-20 top-full border-zinc-300 border-b-4 border-r-4 absolute'></div>
      </div>
     </section>

     <section className='flex flex-col justify-center items-center mt-3 md:mt-6'>
        <span className='text-stone-500 font-bold text-3xl md:text-4xl'>0.5</span>
          <h2 className='text-stone-500 font-bold text-lg md:text-xl'>AVERAGE DEVIATION</h2>
          <p className="text-stone-500">Ocasionally up to 1.5</p>
        <Link href="/accuracy-and-quality" className="flex items-center justify-center w-56 h-6 md:w-72 md:h-9 bg-blue-900 rounded-lg shadow text-white text-xs md:text-base font-normal my-4 md:mt-6 ">LEARN MORE ABOUT DIFERENCE</Link>
     </section>   
  </> 
}

export default ScoreComparison;