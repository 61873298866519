import React from 'react';

function Footer() {
    const currentYear = new Date().getFullYear();

    return (
        <div className="bg-[#4C63E7] w-full">
            <footer className="p-4 mt-8 m-auto max-w-3xl">
                <div className="container mx-auto flex flex-col md:flex-row justify-between items-start md:items-center text-white">
                    {/* Company information */}
                    <div className="mb-4 md:mb-0 md:pr-4">
                        <p className="font-bold mb-2">Yurik BV</p>
                        <p>Location: Delft, NL</p>
                        <p>Email: <a href="mailto:yura@yura.ai" className="hover:text-blue-300 underline">yura@yura.ai</a></p>
                        <p>© {currentYear} Yurik BV</p>
                    </div>

                    {/* Footer links */}
                    <div className="mb-4 md:mb-0">
                        <p>
                            <a href="/accuracy-and-quality" className="hover:text-blue-300 pl-2 underline">Essay Checker Accuracy</a>
                        </p>
                        <p>
                            <a href="/teacher-dashboard" className="hover:text-blue-300 pl-2 underline">For Teachers</a>
                        </p>
                        <p>
                            <a href="/scholarship" className="hover:text-blue-300 pl-2 underline">Scholarship</a>
                        </p>
                        <p>
                            <a href="/team" className="hover:text-blue-300 pl-2 underline">Team</a>
                        </p>
                        <p>
                            <a href="/articles" className="hover:text-blue-300 pl-2 underline">Articles</a>
                        </p>
                        <p>
                            <a href="/ielts-essay-course" className="hover:text-blue-300 pl-2 underline">IELTS writing task 2 course</a>
                        </p>
                        {/* Add more links as needed */}
                        {/* <a href="/blog" className="hover:text-blue-300 pl-2 underline">Blog</a> */}
                    </div>
                </div>
            </footer>
        </div>
    );
}

export default Footer;
